import { haveWorkingHoursElapsed } from "../lib/dates";

// const BASE_URL = "https://chomperlabs.ue.r.appspot.com";
const BASE_URL = " https://cementworks.ngrok.dev";

export default (opt = {}) => ({
	STATUSES: {},
	status: null,
	cancelled: false,
	delivered: false,
	numSteps: 3,
	currentStep: 0,
	containsReorderProducts: null,
	date_shipped: null,
	date_updated: null,
	customerEmail: null,
	shortStatus: null,
	longStatus: null,
	error: false,
	message: null,
	progress: null,
	loading: true,
	...opt,
	async init() {
		const statuses = document.getElementById("order-statuses");
		const statusesJson = JSON.parse(statuses.textContent || "{}");
		this.STATUSES = statusesJson;

		if (this.numSteps === 2) await this.getSimpleShipmentStatus();
		if (this.numSteps === 7) await this.getShipmentStatus();
	},
	async getSimpleShipmentStatus() {
		if (this.cancelled) {
			this.currentStep = 0;
			this.shortStatus = this.STATUSES.SIMPLE_SHORT_STATUS_MAP["Cancelled"];
			this.longStatus = this.STATUSES.SIMPLE_LONG_STATUS_MAP["Cancelled"];
			this.loading = false;
			return;
		}

		if (this.status === "Complete" || this.fulfillmentStatus === "fulfilled" || this.fulfillmentStatus === "partial") {
			this.currentStep = 2;
			this.shortStatus = this.STATUSES.SIMPLE_SHORT_STATUS_MAP["Shipped"];
			this.longStatus = this.STATUSES.SIMPLE_LONG_STATUS_MAP["Shipped"];
			this.loading = false;
			return;
		}

		this.currentStep = 1;
		this.shortStatus = this.STATUSES.SIMPLE_SHORT_STATUS_MAP["Confirmed"];
		this.longStatus = this.STATUSES.SIMPLE_LONG_STATUS_MAP["Confirmed"];
		this.loading = false;
		return;
	},
	async getShipmentStatus() {
		try {
			if (this.cancelled) {
				this.currentStep = 0;
				this.shortStatus = this.STATUSES.SHORT_STATUS_MAP["Cancelled"];
				this.longStatus = this.STATUSES.LONG_STATUS_MAP["Cancelled"];
				this.loading = false;
				return;
			}

			if (this.trackingStatus === "delivered") {
				this.currentStep = 6;
				this.shortStatus = this.STATUSES.SHORT_STATUS_MAP["Delivered"];
				this.longStatus = this.STATUSES.LONG_STATUS_MAP["Delivered"];
				this.loading = false;
				return;
			}

			if (this.status === "Incoming") {
				this.currentStep = 1;
				this.shortStatus = this.STATUSES.SHORT_STATUS_MAP["Incoming"];
				this.longStatus = this.STATUSES.LONG_STATUS_MAP["Incoming"];
				this.loading = false;
				return;
			}

			if (this.status === "Waiting on Retake") {
				this.currentStep = 1;
				this.shortStatus = this.STATUSES.SHORT_STATUS_MAP["Waiting on Retake"];
				this.longStatus = this.STATUSES.LONG_STATUS_MAP["Waiting on Retake"];
				this.loading = false;
				return;
			}

			if (this.status === "In Progress") {
				const res = await fetch(`${BASE_URL}/order-info/${this.customerEmail}`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				})
					.then((res) => res.json())
					.catch(() => {
						this.error = true;
						this.message = this.error_msg;
					});

				const { data } = res;

				if (!data) {
					this.currentStep = 0;
					this.error = true;
					this.message = this.error_msg;
					this.loading = false;
					return;
				}

				this.date_shipped = data.date_shipped;
				this.date_updated = data.date_updated;

				if (haveWorkingHoursElapsed(this.date_updated, 15)) {
					// console.log("In Progress + 15");
					this.currentStep = 5;
					this.shortStatus = this.STATUSES.SHORT_STATUS_MAP["In Progress + 15"];
					this.longStatus = this.STATUSES.LONG_STATUS_MAP["In Progress + 15"];
				} else if (haveWorkingHoursElapsed(this.date_updated, 7)) {
					// console.log("In Progress + 7");
					this.currentStep = 4;
					this.shortStatus = this.STATUSES.SHORT_STATUS_MAP["In Progress + 7"];
					this.longStatus = this.STATUSES.LONG_STATUS_MAP["In Progress + 7"];
				} else if (haveWorkingHoursElapsed(this.date_updated, 3)) {
					// console.log("In Progress + 3");
					this.currentStep = 3;
					this.shortStatus = this.STATUSES.SHORT_STATUS_MAP["In Progress + 3"];
					this.longStatus = this.STATUSES.LONG_STATUS_MAP["In Progress + 3"];
				} else {
					// console.log("In Progress");
					this.currentStep = 2;
					this.shortStatus = this.STATUSES.SHORT_STATUS_MAP["In Progress"];
					this.longStatus = this.STATUSES.LONG_STATUS_MAP["In Progress"];
				}
				this.loading = false;
			}

			if (this.status === "Complete") {
				this.currentStep = 6;
				this.shortStatus = this.STATUSES.SHORT_STATUS_MAP["Shipped"];
				this.longStatus = this.STATUSES.LONG_STATUS_MAP["Shipped"];
				this.loading = false;
				return;
			}
		} catch (e) {
			console.error(e);
		}
	},
});
